<template>
     <div class="footer">
        <div class="link_zone">
            <router-link to="/blog">Blog</router-link>
        </div>
        <div class="Email_zone">Broader@Gmail.com</div>
    </div>
</template>
<script setup>
</script>
<style lang="less" scoped>
@media (max-width: 575px) {
    footer{
        padding: 10px 20px!important;
    }
}
.footer {    background-color: black;
    height: 100px;

    color: white;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    padding: 10px 2vw;
    margin-top: 100px;
    .Email_zone {
        font-size:1rem;
        max-width: 2w;

    }
    a{
        text-decoration: none;
        color: white;
    }
}
</style>