<template>
  <div class="tag_zone">
    <h1 class="tag_title">Tags</h1>
    <div class="tags">
      <div class="tag" v-for="(item, index) in tags_list" :key="index">
        {{ item.value }}
      </div>
    </div>
  </div>

  <div class="other_info_zone">
    <div class="platform">
      <h1 class="platform_title">Platform</h1>
      <div class="platform_box">
        <div class="platform_name">
          <p>TikTok</p>
          <p>YouTube</p>
          <p>Instagram</p>
          <p>Twitter</p>
          <p>Facebook</p>
          <p>twitch</p>
        </div>
        <div class="links_box">
          <a :href="user_info_body.homePage">{{ user_info_body.homePage }}</a>
          <a :href="user_info_body.youTube">{{ user_info_body.youTube }}</a>
          <a :href="user_info_body.instagram">{{ user_info_body.instagram }}</a>
          <a :href="user_info_body.twitter">{{ user_info_body.twitter }}</a>
          <a :href="user_info_body.twitch">{{ user_info_body.facebook }}</a>
          <a :href="user_info_body.amazon">{{ user_info_body.twitch }}</a>
        </div>
      </div>
    </div>
    <div class="dis_zone">
      <textarea placeholder="Describe your account." v-model="user_info_body.description" disabled></textarea>
    </div>
  </div>

  <!-- 其他联系方式 -->

  <div class="other_con">
    <h1 class="other_con_title">Other Contacts</h1>
    <div class="other_con_box">
      <div class="other_con_name">
        <p>Phone</p>
        <p>Telegram</p>
        <p>WhatsApp</p>
        <p>SnapChat</p>
      </div>
      <div class="links_box">
        <p>{{ user_info_body.moblie }}</p>
        <p>{{ user_info_body.telegram }}</p>
        <p>{{ user_info_body.whatsApp }}</p>
        <p>{{ user_info_body.snapchat }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUpdated,onBeforeUnmount, inject } from "vue";

const axios = inject("$axios");

let user_info_body = ref({});
let tags_list = ref([]);

// 过滤数组的方法
function filterByKeyValues(arr, keys) {
  let keySet = new Set(keys);
  return arr.filter(obj => keySet.has(obj.key.toString()));
}

// 获取用户的参数和标签列表
onMounted(async () => {
  try {
    const res = await axios.get("/api/client/broader-user/get-user-profile");
    user_info_body.value = res.data.data;
    
    const tags_list_res = await axios.get('/api/client/data/get-tag-list');
    tags_list.value = filterByKeyValues(tags_list_res.data.data, user_info_body.value.tags);
  } catch (error) {
    console.error('Failed to load user profile or tags list', error);
  }
});

// onUpdated(async ()=>{
//   try {
//     const res = await axios.get("/api/client/broader-user/get-user-profile");
//     user_info_body.value = res.data.data;
    
//     const tags_list_res = await axios.get('/api/client/data/get-tag-list');
//     tags_list.value = filterByKeyValues(tags_list_res.data.data, user_info_body.value.tags);
//   } catch (error) {
//     console.error('Failed to load user profile or tags list', error);
//   }
// })


</script>


<style lang="less">
// 多端适配开始
/* 超大屏幕 */
@media (min-width: 1200px) {}

/* 大屏幕 */
@media (min-width: 990px) and (max-width: 1199px) {}

/* 中屏幕 */
@media (min-width: 768px) and (max-width: 991px) {
  .other_info_zone {
    display: block !important;

    .platform {
      width: 100% !important;
      margin-bottom: 20px;
    }

    .dis_zone {
      width: 100% !important;
    }
  }

  .other_con {
    margin-bottom: 100px !important;
  }
}

/* 小屏幕 */
@media (min-width: 576px) and (max-width: 767px) {
  .other_info_zone {
    display: block !important;

    .platform {
      width: 100% !important;
      margin-bottom: 20px;
    }

    .dis_zone {
      width: 100% !important;
    }
  }

  .other_con {
    margin-bottom: 100px !important;
  }
}

/* 超小屏幕 */
@media (max-width: 575px) {
  .other_info_zone {
    display: block !important;
    width: 100% !important;

    .platform {
      width: 100% !important;
      margin-bottom: 20px;
    }

    .dis_zone {
      width: 100% !important;
    }
  }

  .other_con {
    width: 100% !important;
    height: auto !important;
    margin-bottom: 0px !important;
  }

  .platform_title {
    margin-bottom: 20px !important;
  }

  .links_box {
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 200px !important;
    }
  }

  .footer {
    margin-top: 0px !important;
  }
}

// 多端适配结束

.tag_zone {
  margin-top: 20px;

  .tags {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;

    .tag {
      background-color: #f89e38;
      padding: 10px 20px;
      border-radius: 50px;
      font-weight: bolder;
      color: rgb(255, 255, 255);
    }
  }
}

.other_info_zone {
  display: flex;
  padding-top: 30px;
  width: 100%;
  gap: 20px;
  min-height: 200px;
}

.platform_title {
  margin: 0 0 30px 0;
}

.platform {
  border: 1px solid rgba(166, 166, 166, 0.28);
  width: 70%;
  height: 320px;
  // box-sizing: border-box;
  padding: 20px 30px!important;
  border-radius: 18px;
  box-shadow: 0 4px 8px rgba(145, 145, 145, 0.171);
  overflow: hidden;
}

.platform_box {
  margin-top: 20px;
  display: flex;
  justify-content: space-between !important;

  div {
    display: flex;
    flex-direction: column;
    gap: 5x;
    p{
      height: 20px;
    }
  }

  .links_box {
    a {
      width: 20vw;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      height: 20px;
    }
  }
}

.dis_zone {
  width: 30%;
  height: 320px;
  display: flex;

  textarea {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 20px;
    line-height: 20px;
    border-radius: 10px;
    border: 1px solid rgba(83, 83, 83, 0.062);
    background-color: rgba(212, 212, 212, 0.062);
    resize: none;
  }
}

.other_con_title {
  margin: 30px 0 30px 0;
}

.other_con {
  width: 60%;
  height: 320px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 18px;
  overflow: hidden;
  margin-bottom: 0px;
}

.other_con_box {
  display: flex;
  gap: 40px;

  div {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
textarea{
  border: none!important;
  outline: rgba(128, 128, 128, 0.589);
}
</style>
