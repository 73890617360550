// 创建 axios 实例
import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://api.bdint.top', // 替换为你的 API 基础 URL
  timeout: 10000, // 请求超时时间
});

// 请求拦截器
instance.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    // 例如添加授权 token
    if (localStorage.getItem('authToken')) {
      const token = localStorage.getItem('authToken')
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  response => response,
  error => {
    // 处理响应错误
    return Promise.reject(error);
  }
);

export default instance;
