<template>
  <!-- 导航栏 -->
  <div class="nav">
    <!-- <img src="../assets/logo.jpg" class="logo" @click="to_home()"> -->
    <h1 src="../assets/logo.jpg" class="logo" @click="to_home()">  Broader</h1>

    <a href="#" class="join_btn" @click.prevent="to_User()">Login/Register</a>
  </div>

</template>


<script setup>
import {useRouter} from 'vue-router'
const router = useRouter()
const to_home = () => {
  router.push("/");
};

const to_User = ()=>{
  console.log(1);
  router.push("/user");
}
</script>

<style scoped lang="less">
@media (max-width: 576px) {
  .nav {
  justify-content: space-between!important;
  box-sizing: border-box;
  padding: 10px 25px!important;


.logo {
  width: 15vw!important;
  font-weight: bolder;
  margin-top: 5px;
  font-size: 20px!important;
  line-height: 20px!important;
  cursor: pointer;
  max-width: 70px;
  letter-spacing: -1px;
  padding: 0!important;
}
  }
  .join_btn{
    width: 100px!important;
  }
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  position: fixed;
  width: 100vw;
  top: 0;
  background-color: rgba(255, 255, 255, 0.405);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  z-index: 10; /* 设置导航栏在最上层 */
  user-select:none;

}

.logo {
  width: 15vw;
  font-weight: bolder;
  font-size: 25px;
  cursor: pointer;
  max-width: 60px;
  letter-spacing: -1px;
  color:black;
}

.join_btn {
  text-decoration: none;
  background-color: #0a243f;
  width: 20vw;
  max-width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50px;
  font-size: 15px;
  padding-top: 2px;
  color: white;
  padding: 2px 15px 0 15px;
  box-sizing:content-box;
}

</style>
