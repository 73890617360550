<template>
    <div class="sign_form">
        <h1>Let the world know who you are</h1>
        <h3>Unleash Your Potential, Ignite Your Spark</h3>

        <!-- Progress bar -->
        <div class="Progress_bar">
            <div class="progress"></div>
        </div>

        <!-- form zone -->
        <form @submit.prevent="handleSubmit" class="login_form">
            <div class="input_zone">
                <div class="text_input_area input_text">
                    <label for="fileInput" class="custom_file_input" v-if="!form.avatarLink">
                        Avatar
                    </label>
                    <img :src="form.avatarLink" alt="" v-if="form.avatarLink">
                    <input type="file" id="fileInput" @change="handleFileChange" class="file_input">
                </div>

                <div class="twoinone_Line">
                    <div class="input_text">
                        <!-- 艺术家名称输入框 -->
                        <input type="text" placeholder="Artist Name" v-model="form.artistName" />
                    </div>

                    <div class="input_text">
                        <!-- 艺术家名称输入框 -->
                        <!-- <input type="text" placeholder="Gender" v-model="form.gender" /> -->
                        <select v-model="form.gender" placeholder="Gender">
                            <option value="" disabled selected>Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                </div>

                <div class="text_input_area input_text">
                    <!-- 国家列表 -->
                    <select v-model="form.country" placeholder="Country">
                        <option value="" disabled selected>Country</option>
                        <option :value="item.key" v-for="(item, index) in country_list" :key="index">{{ item.value }}
                        </option>
                    </select>
                </div>

                <div class="text_input_area input_text">
                    <!-- 粉丝数量列表 -->
                    <!-- <input type="text" v-model="form.followers" placeholder="How Many Followers You have"> -->
                    <select v-model="form.followers" placeholder="Country">
                        <option value="" disabled selected>How Many Followers You have</option>
                        <option :value="item.key" v-for="(item, index) in followers_list" :key="index">{{ item.value }}
                        </option>
                    </select>
                </div>
                <div class="text_input_area input_text">
                    <input type="text" v-model="form.tiktokLink" placeholder="Your TikTok HomePage Link">
                </div>
                <!-- 渲染tag 列表 -->
                <div class="text_input_area">
                    <p>Style of your account</p>
                    <div class="type_box">
                        <div class="tk_type" v-for="type in form.accountTypes" :key="type.name"
                            @click="type.selected = !type.selected" :class="{ 'selected': type.selected }">
                            <p>{{ type.value }}</p>
                        </div>
                    </div>
                </div>
                <div class="text_input_area input_text">
                    <textarea
                        placeholder="Describe your account. For example: I am a TikToker specializing in family video content. My videos cover various aspects of family life, including family activities, daily moments, and parent-child interactions. Through my videos, I share joyful moments of family life , aiming to bring happiness and inspiration to my audience"
                        @focus="clearDefaultText($event)" v-model="form.description">
                    </textarea>
                </div>
            </div>
        </form>

        <!-- 下一步按钮 -->
        <div class="nextStep" @click="to_step_3()">
            <p>Next</p>
        </div>
    </div>
</template>

<script setup>
import { reactive, inject, computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';

const axios = inject('$axios');
const router = useRouter();

const form = reactive({
    artistName: '',
    gender: '',
    avatar: null,
    avatarLink: '',
    country: '',
    followers: '',
    tiktokLink: '',
    description: '',
    accountTypes: []
});

const country_list = ref([]);
const followers_list = ref([]);
// const tags_list = ref([]);


//预加载所需参数
onMounted(async () => {

    // //判断用户步骤
    try{const step_res = await axios.get('/api/client/broader-user/get-user-state') 
    console.log(step_res.data.data.regState);
    if(step_res.data.data.regState != 1){
        switch (step_res.data.data.regState){
            case 1 :{router.push('/signupform_2');return;}
            case 2 :{router.push('/signupform_3');return;}
            case 3 :{router.push('/signupform_4');return;}
        }
    }}
    catch{
        router.push('/signup');
    }
    
    // 地区列表获取
    try {
        const country_list_res = await axios.get('/api/client/data/get-nation-list');
        country_list.value = country_list_res.data.data;
    } catch (error) {
        console.error('Failed to load country list', error);
    }
    // 粉丝数量列表获取
    try {
        const followers_list_res = await axios.get('/api/client/data/get-number-of-followers');
        followers_list.value = followers_list_res.data.data;
    } catch (error) {
        console.error('Failed to load follower list', error);
    }
    // 账号类型列表获取
    try {
        const tags_list_res = await axios.get('/api/client/data/get-tag-list');
        // 把请求来的数值转化成可用的数组
        form.accountTypes = tags_list_res.data.data.map(item => ({...item,selected:false}))
    } catch (error) {
        console.error('Failed to load tags list', error);
    }
});



const to_step_3 = async () => {
    console.log(form,tags.value);
    // 表单验证
    if (!form.artistName || !form.gender || !form.country || !form.followers || !form.tiktokLink || !form.description || tags.value.length === 0) {
        ElMessage({
            message: 'Please fill in all the fields!',
            type: 'warning',
            customClass: 'cus_class',
            offset: 80,
        });
        return;
    }

    let formData = {
        artistName: form.artistName,
        gender: form.gender,
        countryCode: form.country,
        followers: form.followers,
        homePage: form.tiktokLink,
        tags: tags.value,
        description: form.description
    };

    try {
        const res = await axios.post('/api/client/broader-user/reg-second', formData);
        if (res.data.success) {
            router.push('/signupform_3');
        }
    } catch (err) {
        console.log(err);
    }
};
// 监听
let tags = computed(() => {
    return form.accountTypes
        .filter(type => type.selected)
        .map(type => type.key);
});

const handleFileChange = (event) => {
    const file = event.target.files[0]; // 获取用户选择的文件对象

    // 创建 FormData 对象，用于上传文件
    let formData = new FormData();
    formData.append('stream', file); // 'avatar' 是后端接收文件的字段名

    // 发送 POST 请求上传文件
    axios.post('/api/client/broader-user/upload-avatar', formData)
        .then(response => {
            // 处理上传成功的逻辑
            if (response.data.data) {
                console.log('文件上传成功', response.data.data);
                //把头像链接保存
                form.avatarLink = response.data.data;
            }
        })
        .catch(error => {
            // 处理上传失败的逻辑
            console.error('文件上传失败', error);
        });
};

const clearDefaultText = (event) => {
    if (event.target.value === event.target.placeholder) {
        event.target.value = '';
    }
};
</script>
<style lang="less" scoped>

@media (max-width: 575px) {
    .nextStep {
        margin-top: 20px;
    }

    .sign_form {
        width: 100vw !important;
        box-sizing: border-box!important;
        padding-top: 150px!important;
    }
    
    .login_form{
        width: 100vw!important;
        // overflow-x: hidde
    }

    .type_box {
        display: flex!important;
        flex-wrap: wrap; // 允许换行
        gap: 10px; // 设置标签之间的间距
        max-height: none; // 移除最大高度限制
        overflow-y: visible; // 确保不显示滚动条
        height: auto; // 自动适应内容高度
    }
    
    .tk_type {
        flex: 1 1 45%; // 每个标签占据大约45%的宽度，允许换行
        box-sizing: border-box;
        padding: 5px 10px;
        font-size: 10px;
        line-height: 20px;
        transition: .1s all ease-in-out;
    }
}

.nextStep {
    background-color: #023047;
    color: white;
    width: 50%;
    text-align: center;
    height: 50px;
    line-height: 50px;
    border-radius: 10px;
    p{
        text-align: center;
    }
}

.Progress_bar {
    width: 50%;
    height: 20px;
    /* 添加一个合适的高度值 */
    background-color: rgba(128, 128, 128, 0.11);
    border-radius: 20px;
    margin-top: 20px;

    /* 进度条样式 */
    .progress {
        width: 33%;
        height: 100%;
        background: linear-gradient(to right, #1d8fec, #c471ed, #f64f59);
        background-size: 400% 100%;
        border-radius: 20px;
        animation: gradient 5s ease infinite;
    }
}

.sign_form {
    width: 60vw;
    height: 1200px;
    padding: 100px 30px 50px 30px;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    h1 {
        font-size: 3rem;
        max-width: 1000px;
        text-align: center;
        // text-shadow: -1px -1px 0px #00b0b997, 1px 1px 0px #b9002b97;
    }

    h3 {
        text-align: center;
        background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
        background-size: 400% 400%;
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        animation: gradient 4s ease infinite;
        text-shadow: 0px 0px 2px #c471ed7a;
    }

    .input_zone {
        width: 100%;

        .twoinone_Line {
            padding: 0 20px;
            box-sizing: border-box;
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 10px;

            .input_text {
                flex: 1;

                input {
                    width: 100%;
                }
            }
        }

        .input_text {
            flex: 1;

            input {
                width: 100%;
            }
        }
    }
}

.type_box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    height: 300px;
    border-radius: 10px;
    border: 1px solid rgba(83, 83, 83, 0.178);
    background-color: rgba(212, 212, 212, 0.062);
    box-sizing: border-box;
    padding: 20px;
    overflow-y: scroll;

    .tk_type {
        border-radius: 10px;
        border: 1px solid rgba(83, 83, 83, 0.178);
        padding: 5px;
        cursor: pointer;
        color: gray;
        line-height: 50px;
        box-sizing: border-box;
        padding: 5px 10px;
        font-size: 10px;
        line-height: 20px;
        transition: .1s all ease-in-out;
    }
}

.text_input_area {
    margin: 20px;

    p {
        margin: 20px;
        font-weight: bolder;
    }

    img {
        width: 100px;
        height: 100px;
        border-radius: 10px;
        display: block;
        margin: 0 auto;
        object-fit: cover
    }

    textarea {
        width: 100%;
        height: 200px;
        margin-top: 0px;
        box-sizing: border-box;
        padding: 20px;
        line-height: 20px;
        border-radius: 10px;
        border: 1px solid rgba(83, 83, 83, 0.43)!important;
        background-color: rgba(212, 212, 212, 0.062);
        resize: none;
    }

    label {
        width: 100%;
    }
}

input {
    height: 50px;
    border-radius: 10px;
    border: 1px solid rgba(83, 83, 83, 0.062);
    background-color: rgba(212, 212, 212, 0.062);
    box-sizing: border-box;
    padding: 20px;
    flex-shrink: 0;
}

.file_input {
    display: none;
}

.custom_file_input {
    display: inline-block;
    cursor: pointer;
    box-sizing: border-box;
    padding: 20px;
    line-height: 20px;
    border-radius: 10px;
    border: 1px solid rgba(83, 83, 83, 0.062);
    background-color: rgba(212, 212, 212, 0.062);
    color: gray;
}

select,
select:focus {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: 1px solid rgba(83, 83, 83, 0.062);
    background-color: rgba(212, 212, 212, 0.062);
    box-sizing: border-box;
    padding: 0 20px;
    flex-shrink: 0;

}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.selected {
    background-color: #1d8fec;
    color: white !important;

}
</style>
