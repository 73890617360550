<template>
  <!-- 导航栏 -->
  <my-nav></my-nav>

  <div class="article_body" v-if="article_body">
    <img :src="article_body.imgUrl" alt="" />
    <h1>{{ article_body.title }}</h1>
    <h3>{{ article_body.summary }}</h3>
    <!-- <div v-html="article" class="article"></div> -->
    <v-md-preview :text="article" style="padding: 0;"></v-md-preview>
  </div>

  <el-skeleton :loading="!article_body" animated v-else>
    <template #template>
      <div class="skeleton-container">
        <el-skeleton-item variant="image" style="width: 100%; height: 300px;" />
        <el-skeleton-item variant="h1" style="width: 70%;height: 300px;" />
        <el-skeleton-item variant="text" style="width: 100%; margin-top: 50px;" />
        <el-skeleton-item variant="text" style="width: 100%; margin-top: 50px;" />
        <el-skeleton-item variant="text" style="width: 100%; margin-top: 50px;" />
      </div>
    </template>
  </el-skeleton>

  <my-footer></my-footer>

</template>

<script setup>
import { ref, onMounted, computed, inject } from "vue";
import { useRoute } from 'vue-router';
import myNav from '../components/myNav.vue';
import myFooter from '../components/myFooter.vue';
import { ElSkeleton, ElSkeletonItem } from 'element-plus';
import MarkdownIt from 'markdown-it';
const markdown = new MarkdownIt()

const axios = inject("$axios");
const router = useRoute();

let article_body = ref(null);

// 拿到id
let id = computed(() => router.query.id);
let article = ''
onMounted(async () => {
  try {
    let article_res = await axios.get('/api/client/blog/get-blog', { params: { id: id.value } });
    article_body.value = article_res.data.data;
    // article = article_body.value.content;
  } catch (error) {
    console.error('Error fetching article:', error);
  }
  //渲染文章
  article =  markdown.render(article_body.value.content)
});
</script>

<style scoped lang="less" >
@media (max-width: 575px) {
  .article_body {
    width: 100vw !important;
    padding: 120px 20px !important;
    min-height: 100vh;
    box-sizing: border-box;

    h1 {
      font-size: 3rem !important;
      line-height: 4rem !important;
      margin-bottom: 20px !important;
      text-wrap: wrap;
    }
  }
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
  position: fixed;
  width: 100vw;
  top: 0;
  background-color: rgba(249, 249, 249, 0.776);
  backdrop-filter: blur(15px);
  z-index: 10;
  /* 设置导航栏在最上层 */
}

.logo {
  font-weight: bolder;
  font-size: 30px;
}

.join_btn {
  text-decoration: none;
  color: #fff;
  background-color: #023047;
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50px;
}

.article_body {
  width: 70vw;
  margin: 0 auto;
  padding-top: 100px;
  line-height: 40px;
  min-height: 100vh !important;
  display: block;

  h1 {
    margin-bottom: 100px;
    font-size: 70px;
    line-height: 75px;
  }

  img {
    width: 100%;
    max-height: 300px;
    display: block;
    border-radius: 20px;
    margin-bottom: 40px !important;
    margin: 0 auto;
    object-fit: cover;
  }
}

.skeleton-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding-top: 100px;
  box-sizing: border-box;
}

.dropcap::first-letter {
  font-size: 3em;
  float: left;
  line-height: 1;
  padding-right: 8px;
  padding-top: 4px;
}

.footer {
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;

  .Email_zone {
    font-size: 30px;
  }
}
.vuepress-markdown-body{
  padding: 0!important;
  color: black!important;
}
</style>
