<template>
    <div>
        <!-- Navigation -->
        <my-nav></my-nav>


        <!-- Banner section -->
        <div class="banner">
            <div class="banner_text">
                <h1>Turn Your Influence into Cash-Join BROADER Now</h1>
                <h3>
                    Harness the power of your social media presence with our expert
                    strategies to generate consistent and significant income.
                </h3>
            </div>
        </div>

        <!-- Intro section -->
        <div class="intro">
            <p>
                Transforming
            <span class="trans">KOL</span> Influence into <span class="trans">Profitable</span> Opportunities: Partner with
            Experts at Maximizing <span class="trans">TikTok's Potential</span>
            </p>
        </div>

        <!-- Pic section -->
        <div class="pic_zone">
            <div v-for="(item, index) in images" :key="index">
                <img :src="item" alt="" srcset="">
            </div>
        </div>
    </div>

    <!-- service section -->
    <div class="sevice_zone">
        <h2>
            Services
        </h2>

        <!-- service card 广告位1 -->
        <div class="service_card" @click="toArticle(blog_ad_1.id)">
            <div class="article_zone">
                <h1>{{ blog_ad_1.title }}</h1>
                <h3>{{ blog_ad_1.summary }}</h3>
            </div>
            <div class="card_pic">
                <img :src="blog_ad_1.imgUrl" alt="">
            </div>
        </div>

        <!-- service card 广告位2 -->
        <div class="service_card" @click="toArticle(blog_ad_2.id)">
            <div class="card_pic">
                <img :src="blog_ad_2.imgUrl" alt="">
            </div>
            <div class="article_zone">
                <h1>{{ blog_ad_2.title }}</h1>
                <h3>{{ blog_ad_2.summary }}</h3>
            </div>
        </div>

        <!-- col card box -->
        <div class="col_card_box">
            <!-- service cul card  广告位 3 -->
            <div class="service_cul_card" @click="toArticle(blog_ad_3.id)">
                <div class="card_pic">
                    <img :src="blog_ad_3.imgUrl" alt="">
                </div>
                <div class="article_zone">
                    <h3>{{ blog_ad_3.summary }}</h3>
                </div>
            </div>
            <!-- service cul card  广告位 4 -->
            <div class="service_cul_card" @click="toArticle(blog_ad_4.id)">
                <div class="card_pic">
                    <img :src="blog_ad_4.imgUrl" alt="">
                </div>
                <div class="article_zone">
                    <h3>{{ blog_ad_4.summary }}</h3>
                </div>
            </div>
        </div>

    </div>
    <myFooter></myFooter>
</template>


<script setup>
import { ref, onMounted, inject, onUpdated } from "vue";
import { useRouter } from 'vue-router';

const axios = inject("$axios");

const router = useRouter();
// 使用 ref 创建响应式数据  
const images = ref([
    require('@/assets/pic_img1.jpg'),
    require('@/assets/pic_img2.jpg'),
    require('@/assets/pic_img3.jpg'),
    require('@/assets/pic_img4.jpg'),
    require('@/assets/pic_img5.jpg'),
    require('@/assets/pic_img6.jpg'),
    require('@/assets/pic_img7.jpg'),
    require('@/assets/pic_img1.jpg'),
    require('@/assets/pic_img2.jpg'),
    require('@/assets/pic_img3.jpg'),
    require('@/assets/pic_img4.jpg'),
    require('@/assets/pic_img5.jpg'),
    require('@/assets/pic_img6.jpg'),
    require('@/assets/pic_img7.jpg'),


]);
let blog_ad_1 = ref({})
let blog_ad_2 = ref({})
let blog_ad_3 = ref({})
let blog_ad_4 = ref({})
// 获取 blog 列表
onMounted(async () => {
    try {
        const blog_ad_list_res = await axios.get('/api/client/blog/get-home-blog');
        const blogList = blog_ad_list_res.data.data;
        console.log(blogList);
        blog_ad_1.value = blogList[0] || {};
        blog_ad_2.value = blogList[1] || {};
        blog_ad_3.value = blogList[2] || {};
        blog_ad_4.value = blogList[3] || {};
    } catch (error) {
        console.error('Error fetching blog list:', error);
    }
})
// 定义方法  
const toArticle = (id) => {
    console.log(id);
    router.push({ path: '/article', query: { id: id } })
};

// 在 <script setup> 中，不需要显式地导出任何内容  
// Vue 会自动处理导出和组件上下文的绑定  
</script>

<style scoped lang="less">
@gray: rgba(128, 128, 128, 0.24);

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

// 多端适配开始
/* 超大屏幕 */
@media (min-width: 1200px) {
    .example {
        font-size: 1.5rem;
    }
}

/* 大屏幕 */
@media (min-width: 990px) and (max-width: 1199px) {
    .sevice_zone {
        padding: 0 100px !important;
    }
}

/* 中屏幕 */
@media (min-width: 768px) and (max-width: 991px) {
    .sevice_zone {
        padding: 0 0px !important;
    }

    .article_zone {
        width: 25vw;
        flex-shrink: 0;

        h1 {
            font-size: 2.5rem !important;
            line-height: 2.5rem !important;
            margin-bottom: 40px;
        }
    }

    .col_card_box {
        display: block !important;

        .service_cul_card {
            width: 100% !important;

            .article_zone {
                width: 80% !important;
                flex-shrink: 0;

                h1 {
                    font-size: 50px;
                    line-height: 50px;
                    margin-bottom: 40px;
                }

                h3 {
                    height: 20vh;
                    max-height: 150px !important;
                    overflow-y: hidden;
                    text-overflow: ellipsis;
                    text-wrap: wrap;
                }
            }

            .card_pic {
                width: 90% !important;
                flex-shrink: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;

                img {
                    width: 100%;
                    max-width: 900px;
                    max-height: 700px;
                    height: 100%;
                    border-radius: 40px;
                }
            }
        }
    }
}

/* 小屏幕 */
@media (min-width: 576px) and (max-width: 767px) {
    .sevice_zone {
        padding: 0 0px !important;
    }

    .article_zone {
        width: 25vw;
        flex-shrink: 0;

        h1 {
            font-size: 2.5rem !important;
            line-height: 2.5rem !important;
            margin-bottom: 40px;
        }
    }

    .service_card {
        width: 90vw !important;

        .article_zone {
            width: 40vw !important;
        }
    }

    .col_card_box {
        display: block !important;
        width: 100vw !important;

        .service_cul_card {
            width: 90vw !important;

            .article_zone {
                width: 80% !important;
                flex-shrink: 0;

                h1 {
                    font-size: 50px;
                    line-height: 50px;
                    margin-bottom: 40px;
                }

                h3 {
                    height: 20vh;
                    max-height: 150px !important;
                    overflow-y: hidden;
                    text-overflow: ellipsis;
                    text-wrap: wrap;
                }
            }

            .card_pic {
                width: 90% !important;

                flex-shrink: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;

                img {
                    width: 100%;
                    max-width: 900px;
                    max-height: 700px;
                    height: 100%;
                    border-radius: 40px;
                }
            }
        }
    }
}

/* 超小屏幕 */
@media (max-width: 575px) {
    .banner {
        background-image: url('@/assets/banner_mo.jpg')!important;
        padding: 20px !important;
        height: 80vh !important;
    }

    .intro {
        padding: 70px !important;
        font-size: 1rem !important;
    }

    .sevice_zone {
        padding: 0 0px !important;
    }

    .article_zone {
        width: 25vw;
        flex-shrink: 0;

        h1 {
            font-size: 2.5rem !important;
            line-height: 2.5rem !important;
            margin-bottom: 20px !important;
        }
    }

    .service_card {
        width: 90vw !important;
        display: flex !important;
        flex-direction: column;
        gap: 40px;
        box-sizing: border-box;
        padding: 40px 20px !important;
        height: auto !important;

        .article_zone {
            width: 100% !important;
        }

        .card_pic {
            width: 100% !important;
            // height: 22vw;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            img {
                width: 100%;
                max-width: 900px;
                max-height: 700px;
                height: 100%;
                border-radius: 40px;
            }
        }
    }

    .col_card_box {
        display: block !important;
        width: 100vw !important;

        .service_cul_card {
            width: 90vw !important;
            display: flex !important;
            flex-direction: column;
            gap: 40px;
            box-sizing: border-box;
            padding: 40px 20px !important;
            height: auto !important;

            .article_zone {
                width: 100% !important;
            }

            .card_pic {
                width: 100% !important;
                // height: 22vw;
                flex-shrink: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;

                img {
                    width: 100%;
                    max-width: 900px;
                    max-height: 700px;
                    height: 100%;
                    border-radius: 40px;
                }
            }
        }


    }

    .trans {
        text-shadow: none !important;
    }
}

// 多端适配结束
.nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0;
    position: fixed;
    width: 100vw;
    top: 0;
    background-color: rgba(249, 249, 249, 0.776);
    backdrop-filter: blur(15px);
}

.logo {
    font-weight: bolder;
    font-size: 30px;
}

.join_btn {
    text-decoration: none;
    color: #fff;
    background-color: #023047;
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50px;
}

.banner {
    background-image: url('@/assets/banner.jpg');
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    padding: 0 100px;
    box-sizing: border-box;
}

.banner_text {
    color: aliceblue;
    max-width: 600px;
}

.banner_text h1 {
    font-size: 3em;
    margin-bottom: 20px;
}

.banner_text h3 {
    font-size: 1.5em;
}

.intro {
    width: 100vw;
    height: auto;
    text-align: center;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 200px 40px;
}

.intro p {
    font-size: 4em;
    max-width: 1000px;
    text-align: center;
    // text-shadow: -2px -2px 0px #00b0b997, 2px 2px 0px #b9002b97;

}

.intro p span.trans {
    display: inline;
    font-size: inherit;
    max-width: 1000px;
    text-align: center;
    background: linear-gradient(45deg, #fb5607, #ffd60a, #9b2226, #f7e457);
    background-size: 400% 400%;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    animation: gradient 4s ease infinite;
    text-shadow: 0px 0px 10px #ffd60a73;
    height: auto;
}

.pic_zone {
    display: flex;
    width: max-content;
    animation: scroll 30s normal linear infinite;

    img {
        width: 400px;
        height: 600px;
    }
}

@keyframes scroll {
    to {
        transform: translateX(calc(-50% - 0.1rem));
    }
}

.sevice_zone {
    box-sizing: border-box;
    padding: 0 200px;

    h2 {
        text-align: center;
        padding: 60px;
    }


}

.service_card {
    border: 1px solid @gray;
    height: 500px;
    width: 70vw;
    margin: 0px auto;
    margin-bottom: 70px;
    box-sizing: border-box;
    border-radius: 50px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .article_zone {
        width: 25vw;
        flex-shrink: 0;

        h1 {
            font-size: 3.125rem;
            line-height: 50px;
            margin-bottom: 40px;
        }
    }

    .card_pic {
        max-width: 450px;
        // max-height: 450px;
        width: 35vw;
        // height: 35vw;
        flex-shrink: 0;

        img {
            width: 100%;
            height: 100%;
            border-radius: 40px;
        }
    }
}

.col_card_box {
    display: flex;
    box-sizing: border-box;
    width: 70vw;
    margin: 0 auto;

    .service_cul_card {
        border: 1px solid @gray;
        width: 33vw;
        margin: 0px auto;
        margin-bottom: 70px;
        box-sizing: border-box;
        border-radius: 50px;
        padding: 30px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 40px;

        .article_zone {
            width: 22vw;
            flex-shrink: 0;

            h1 {
                font-size: 50px;
                line-height: 50px;
                margin-bottom: 40px;
            }

            h3 {
                height: 20vh;
                max-height: 200px;
                overflow-y: hidden;
                text-overflow: ellipsis;
                text-wrap: wrap;
            }
        }

        .card_pic {
            width: 29vw;
            // height: 22vw;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            img {
                width: 100%;
                max-width: 900px;
                max-height: 700px;
                height: 100%;
                border-radius: 40px;
            }
        }
    }
}

img {
    box-shadow: 1px 0px 19px 4px rgba(0, 0, 0, 0.32);
    -webkit-box-shadow: 1px 0px 19px 4px rgba(0, 0, 0, 0.32);
    -moz-box-shadow: 1px 0px 19px 4px rgba(0, 0, 0, 0.32);
}

.service_cul_card,
.service_card {
    box-shadow: 1px 0px 19px 4px rgba(0, 0, 0, 0.04) inset;
    -webkit-box-shadow: 1px 0px 19px 4px rgba(0, 0, 0, 0.04) inset;
    -moz-box-shadow: 1px 0px 19px 4px rgba(0, 0, 0, 0.04) inset;
}

.nav {
    box-shadow: 1px 9px 19px 4px rgba(0, 0, 0, 0.07);

}

.footer {
    height: 200px;
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    padding: 10px 200px;

    .Email_zone {
        font-size: 30px;

    }
}

.link_zone {
    a {
        text-decoration: none;
        color: aliceblue;
    }

}
</style>