<template>
  <div class="user_body">
    <my-nav></my-nav>
    <div class="user_zone">
      <div class="title_zone">
        <h1>HELLO {{ user_info_body.artistName }}!</h1>
        <h3>Here's your introduction card.</h3>
      </div>
      <div class="simple_card_zone">
        <div class="simple_card">
          <!-- 上传头像 -->
          <label for="fileInput" class="custom_file_input">
            <img :src="user_info_body.avatar" alt="" />
          </label>
          <input type="file" id="fileInput" @change="handleFileChange" class="file_input" />

          <!-- <img :src="user_info_body.avatar" alt="" /> -->
          <div class="card_info">
            <p class="user_name">{{ user_info_body.artistName }}</p>
            <p class="followers_num">{{ fans_key_to_value }} Followers</p>
            <p class="user_email">{{ user_info_body.email }}</p>
            <p class="country">{{ country_key_to_value }}</p>
          </div>
        </div>
        <div class="operation">
          <div class="edit" @click="switch_mode()" :class="state_color">
            {{ state }}
          </div>
          <div @click="logout" class="logout">Log Out</div>
        </div>
      </div>
      <!-- 其他信息 -->
      <ShowInfo v-if="show_whitch"></ShowInfo>
      <EditInfo v-if="!show_whitch"></EditInfo>
    </div>
    <!-- 手机端显示 -->
    <div class="phone_op_zone">
      <div class="operation">
        <div class="edit" @click="switch_mode()" :class="state_color">
          {{ state }}
        </div>
        <div class="logout" @click="logout()">Log Out</div>
      </div>
    </div>
    <MyFooter></MyFooter>
  </div>
</template>

<script setup>
import ShowInfo from "@/components/info/show_info.vue";
import EditInfo from "@/components/info/edit_info.vue";
import MyFooter from "@/components/myFooter.vue";
import { ref, onMounted, inject, onUpdated } from "vue";
import { useRouter } from 'vue-router';

const axios = inject("$axios");
const router = useRouter();

let user_info_body = ref({});
let fans_list = ref();
let country_list = ref();
let fans_key_to_value = ref();
let country_key_to_value = ref();

let show_whitch = ref(true);
let state = ref("Edit You Profile");

let state_color = ref("edit_bg");
let avatarLink = ref();

let Edit_state = ref(true);

function filterByKeyValues(arr, keys) {
  let keySet = new Set(keys);
  return arr.filter((obj) => keySet.has(obj.key.toString()));
}

function filterCountryByKeyValues(arr, keys) {
  return arr.filter((obj) => keys == obj.key);
}
// 获取用户的参数
let res = {}
onMounted(async () => {

  fans_list.value = await axios.get("/api/client/data/get-number-of-followers");
  country_list.value = await axios.get("/api/client/data/get-nation-list");
  try {
    res = await axios.get("/api/client/broader-user/get-user-profile");
    user_info_body.value = res.data.data;
  }
  catch {
    router.push('/')
    return
  }



  //过滤认证环节
  // 未审核
  if (user_info_body.value.auditStatus == 0) {
    router.push('/reviewing')
  }
  else if (user_info_body.value.auditStatus == 2) {
    router.push('/denial')
  }
  //过滤 key 到可用标签
  fans_key_to_value = filterByKeyValues(
    fans_list.value.data.data,
    res.data.data.followers
  )[0].value;
  country_key_to_value = filterCountryByKeyValues(
    country_list.value.data.data,
    res.data.data.countryCode
  )[0].value;

});

//点击done时触发的函数(防抖版本)

let timeout;
const fetchData = async () => {
  clearTimeout(timeout);
  timeout = setTimeout(async () => {
    console.log("被触发");
    const res = await axios.get("/api/client/broader-user/get-user-profile");
    user_info_body.value = res.data.data;
    fans_key_to_value = filterByKeyValues(
      fans_list.value.data.data,
      res.data.data.followers
    )[0].value;
    country_key_to_value = filterCountryByKeyValues(
      country_list.value.data.data,
      res.data.data.countryCode
    )[0].value;
  }, 700); // 300ms 的防抖时间
};

const switch_mode = () => {
  if (show_whitch.value) {
    state.value = "Done!";
    show_whitch.value = !show_whitch.value;
    state_color.value = "done_bg";
  } else {
    fetchData();
    state.value = "Edit You Profile";
    show_whitch.value = !show_whitch.value;
    state_color.value = "edit_bg";
  }
};
const handleFileChange = (event) => {
  const file = event.target.files[0]; // 获取用户选择的文件对象

  // 创建 FormData 对象，用于上传文件
  let formData = new FormData();
  formData.append("stream", file); // 'avatar' 是后端接收文件的字段名

  // 发送 POST 请求上传文件
  axios
    .post("/api/client/broader-user/upload-avatar", formData)
    .then((response) => {
      // 处理上传成功的逻辑
      if (response.data.data) {
        console.log("文件上传成功", response.data.data);
        //把头像链接保存
        user_info_body.value.avatar = response.data.data;
        // console.log(user_info_body.value.avatar);
      }
    })
    .catch((error) => {
      // 处理上传失败的逻辑
      console.error("文件上传失败", error);
    });
};

// onUpdated();
// onUpdated(async () => {
//   // console.log('我已经被歘发');
//   const res = await axios.get("/api/client/broader-user/get-user-profile");
//   // console.log(res.data.data);
//   user_info_body.value = res.data.data;
//   // console.log(user_info_body.value);
// })
const logout = () => {
  // console.log(1);
  localStorage.removeItem('authToken')
  router.push('/')
}
</script>

<style lang="less" scoped>
// 多端适配开始
/* 超大屏幕 */
@media (min-width: 1200px) {}

/* 大屏幕 */
@media (min-width: 990px) and (max-width: 1199px) {}

/* 中屏幕 */
@media (min-width: 768px) and (max-width: 991px) {}

/* 小屏幕 */
@media (min-width: 576px) and (max-width: 767px) {}

/* 超小屏幕 */
@media (max-width: 575px) {
  .user_body {
    padding-top: 100px !important;
  }

  .user_zone {
    margin: 0 !important;
    padding: 20px !important;
    width: 100vw !important;
    position: relative;
    overflow: visible;
    box-sizing: border-box;
  }

  .simple_card_zone {
    display: block !important;
    overflow: visible !important;
    position: relative;
    padding-bottom: 0px !important;

    .simple_card {
      width: 100% !important;
    }
  }

  .operation {
    display: flex !important;
    height: 120px !important;
    margin-top: 20px;
    flex-direction: row !important;
    width: 100% !important;
    position: sticky;
    top: 0px;

    .edit,
    .logout {
      width: 50% !important;
      height: 100px !important;
    }

    .edit:hover,
    .logout:hover {
      width: 50% !important;
      height: 100px !important;
      transform: none !important;
    }
  }

  .phone_op_zone {
    display: block !important;
  }
}

// 多端适配结束

@keyframes movement {

  0%,
  100% {
    background-size: 130vmax 130vmax, 80vmax 80vmax, 90vmax 90vmax,
      110vmax 110vmax, 90vmax 90vmax;
    background-position: -80vmax -80vmax, 60vmax -30vmax, 10vmax 10vmax,
      -30vmax -10vmax, 50vmax 50vmax;
  }

  25% {
    background-size: 100vmax 100vmax, 90vmax 90vmax, 100vmax 100vmax,
      90vmax 90vmax, 60vmax 60vmax;
    background-position: -60vmax -90vmax, 50vmax -40vmax, 0vmax -20vmax,
      -40vmax -20vmax, 40vmax 60vmax;
  }

  50% {
    background-size: 80vmax 80vmax, 110vmax 110vmax, 80vmax 80vmax,
      60vmax 60vmax, 80vmax 80vmax;
    background-position: -50vmax -70vmax, 40vmax -30vmax, 10vmax 0vmax,
      20vmax 10vmax, 30vmax 70vmax;
  }

  75% {
    background-size: 90vmax 90vmax, 90vmax 90vmax, 100vmax 100vmax,
      90vmax 90vmax, 70vmax 70vmax;
    background-position: -50vmax -40vmax, 50vmax -30vmax, 20vmax 0vmax,
      -10vmax 10vmax, 40vmax 60vmax;
  }
}

.edit_bg {
  background-color: #ffb700;
  box-shadow: 0 0 20px 5px #d1950067;
}

.done_bg {
  background-color: #e63946;
  box-shadow: 0 0 20px 5px #8f242d98;
  font-size: 3rem !important;
  border-radius: 100px !important;
}

.user_body {
  background-image: radial-gradient(closest-side,
      #e6394760,
      rgba(235, 105, 78, 0)),
    radial-gradient(closest-side, rgba(243, 11, 166, 0), rgba(243, 11, 164, 0)),
    radial-gradient(closest-side,
      rgba(254, 233, 131, 0),
      rgba(254, 234, 131, 0)),
    radial-gradient(closest-side,
      rgba(170, 142, 245, 0),
      rgba(170, 142, 245, 0)),
    radial-gradient(closest-side,
      rgba(248, 193, 147, 0.425),
      rgba(248, 192, 147, 0));
  background-size: 130vmax 130vmax, 80vmax 80vmax, 90vmax 90vmax,
    110vmax 110vmax, 90vmax 90vmax;
  background-position: -80vmax -80vmax, 60vmax -30vmax, 10vmax 10vmax,
    -30vmax -10vmax, 50vmax 50vmax;
  background-repeat: no-repeat;
  animation: 5s movement linear infinite;
}

.user_zone {
  padding: 100px 0 0 0;
  width: 60vw;
  margin: 0 auto;
  min-height: 100vh;
}

.title_zone {
  h1 {
    font-weight: bolder;
    font-size: 3rem;
    // text-shadow: -1px -1px 0px #00b0b997, 1px 1px 0px #b9002b97;
  }
}

.simple_card_zone {
  box-sizing: border-box;
  padding-top: 50px;
  display: flex;
  justify-content: space-around;
  gap: 10px;
  padding-bottom: 40px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.121);
}

.simple_card {
  display: flex;
  border: 1px solid #cccccc48;
  border-radius: 8px;
  padding: 40px 20px;
  box-shadow: 0 4px 8px rgba(145, 145, 145, 0.171);
  width: 65%;
  height: 250px;
  box-sizing: border-box;
  justify-content: space-around;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transform-style: preserve-3d;
  /* 添加3D效果 */
  backdrop-filter: blur(15px);
  background-color: rgba(255, 255, 255, 0.589);
}

.simple_card:hover {
  transform: translateY(-10px) scale(1.05);
  /* 下压效果 */
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.simple_card img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin-right: 20px;
  object-fit: cover;
}

.card_info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;
}

.user_name {
  font-size: 2em;
  font-weight: bold;
}

.followers_num,
.user_email,
.country {
  margin-top: 5px;
  font-size: 1em;
  color: #555;
}

.user_email,
.country {
  color: #55555596;
}

.operation {
  width: 30%;
  height: 250px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .edit,
  .logout {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: white;
    font-weight: bolder;
    font-size: 1.2rem;
    cursor: pointer;
    user-select: none;
  }

  .edit:hover,
  .logout:hover {
    transform: translateY(-10px) scale(1.05);
    /* 下压效果 */
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }

  .edit {
    transition: all 0.3s ease, box-shadow 0.3s ease;
    transform-style: preserve-3d;
    cursor: pointer;
    user-select: none;
    /* 添加3D效果 */
    // transition: 1s ease-in-out;
  }

  .logout {
    background-color: #3a86ff;
    box-shadow: 0 0 20px 5px #19789075;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    transform-style: preserve-3d;
    /* 添加3D效果 */
  }
}

.phone_op_zone {
  box-sizing: border-box;
  padding: 0 20px;
  display: none;
}

.file_input {
  display: none;
}
</style>
