import { createRouter, createWebHashHistory } from "vue-router";
// import { inject } from "vue";
import Home from "../views/Home.vue";
import BlogView from "../views/BlogView.vue";
import Article from "../views/Article.vue";
import User from "../views/User.vue";
import Signup from "../views/SignUp.vue"; // 改正拼写
import Login from "../views/login.vue"; // 确保首字母大写以保持一致
import SignupForm from "../views/signUp/SignupForm.vue"; // 改正拼写
import SignupForm2 from "../views/signUp/sighupForm2.vue"; // 改正拼写
import SignupForm3 from "../views/signUp/sighupForm3.vue"; // 改正拼写
import SignupForm4 from "../views/signUp/sighupForm4.vue"; // 改正拼写
import SighEnd from "../views/signUp/SighEnd.vue"; // 改正拼写
import Passed from "../views/state/Passed.vue"; // 改正拼写
import Denial from "../views/state/Denial.vue"; // 改正拼写
import Reviewing from "../views/state/Reviewing.vue"; // 改正拼写
import User_info from "../views/userInfo/User_info.vue"; // 改正拼写
import axios from "../axios";
import Title from "ant-design-vue/es/typography/Title";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta:{
      title:'Broader|| Influencer marketplace for creators'
    }
  },
  {
    path: "/blog",
    name: "blog",
    component: BlogView,
  },
  {
    path: "/article",
    name: "article",
    component: Article,
  },
  {
    path: "/user",
    name: "user",
    component: User,
  },
  {
    path: "/signup",
    name: "signup",
    component: Signup,
    // meta: { requiresAuth: true }, // 设置需要验证的路由
    children: [
      {
        path: "",
        name: "signupform",
        component: SignupForm,
      },
      {
        path: "/signupform_2",
        name: "signupform2",
        component: SignupForm2,
      },
      {
        path: "/signupform_3",
        name: "signupform3",
        component: SignupForm3,
      },
      {
        path: "/signupform_4",
        name: "signupform4",
        component: SignupForm4,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/sighend",
    name: "sighend",
    component: SighEnd,
  },
  {
    path: "/sighend",
    name: "sighend",
    component: SighEnd,
  },
  {
    path: "/passed",
    name: "Passed",
    component: Passed,
  },
  {
    path: "/denial",
    name: "Denial",
    component: Denial,
  },
  {
    path: "/reviewing",
    name: "Reviewing",
    component: Reviewing,
  },
  {
    path: "/user_info",
    name: "User_info",
    component: User_info,
    meta: { requiresAuth: true }, // 设置需要验证的路由
  },
];
const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition && to.meta.keepAlive) {
    return savedPosition;
  }
  return { left: 0, top: 0 };
};

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior,
});

router.beforeEach(async (to, from, next) => {
  // 获取 token（假设 token 存储在 localStorage 中）
  document.title = to.meta.title || 'Broader|| Influencer marketplace for creators'
  let token_av = true;

  // try {
  //   let res = await axios.get('/api/client/broader-user/get-user-profile')
  // } catch (err) {
  //   // console.log(err.response.data.msg == '未登录');
  //   if(err.response.data.msg == '未登录'){
  //     token_av = false
  //   }
  // }
  // res = res.data.data
  // 需要验证 token 的路由
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !token_av) {
    // 如果没有 token，且路由需要验证，则跳转到登录页面
    try {
      let res = await axios.get("/api/client/broader-user/get-user-profile");
    } catch (err) {
      // console.log(err.response.data.msg == '未登录');
      if (err.response.data.msg == "未登录") {
        token_av = false;
      }
    }
    next({ name: "signupform" });
  } else {
    next();
  }
});

export default router;
