<template>
  <div class="sign_form">
    <h1>Keep In Touch</h1>
    <h3>In case we didn't find you</h3>

    <!-- Progress bar -->
    <div class="Progress_bar">
      <div class="progress"></div>
    </div>

    <!-- 输入区域 -->
    <div class="input_zone">
      <!-- 第一行输入 -->
        <div class="input_text">
          <!-- 输入名称的提示信息（目前为空） -->
          <p class="input_name"></p>
          <input v-model="formData.moblie" type="text" placeholder="Phone" />
        </div>

        <div class="input_text">
          <!-- 输入名称的提示信息（目前为空） -->
          <p class="input_name"></p>
          <input v-model="formData.telegram" type="text" placeholder="telegram" />
        </div>

        <div class="input_text">
          <!-- 输入名称的提示信息（目前为空） -->
          <p class="input_name"></p>
          <input v-model="formData.snapchat" type="text" placeholder="SnapChat" />
        </div>
      <div class="input_text">
        <!-- 输入名称的提示信息（目前为空） -->
        <p class="input_name"></p>
        <input v-model="formData.whatsApp" type="text" placeholder="WhatsApp" />
      </div>
    </div>

    <!-- 下一步按钮 -->
    <div class="nextStep" @click="submitForm()">
      <p>Next</p>
    </div>
  </div>
</template>

<script setup>
import { ref,inject,onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from 'element-plus'

const  axios = inject('$axios') ;

const router = useRouter();
const formData = ref({
  moblie: "",
  snapchat: "",
  whatsApp: "",
  telegram:''
});

onMounted(async () => {
      //判断用户步骤
      try{const step_res = await axios.get('/api/client/broader-user/get-user-state') 
    console.log(step_res.data.data.regState);
    if(step_res.data.data.regState != 1){
        switch (step_res.data.data.regState){
            case 1 :{router.push('/signupform_2');return;}
            case 2 :{router.push('/signupform_3');return;}
            case 3 :{router.push('/signupform_4');return;}
        }
    }}
    catch{
        router.push('/signup');
    }
})

  const submitForm = async () => {
  console.log(formData.value);
      // 表单验证
      if (!formData.value.moblie ) {
        ElMessage({
        message: 'Please leave your phone number!',
        type: 'warning',
        customClass: 'cus_class',
        offset: 80,
      })
        return;
    }

  try {
    const res = await axios.post('/api/client/broader-user/reg-forth', formData.value);
    console.log(res.data);
    if (res.data.success) {
      router.push("sighend");
    } else {
      console.error('用户第四步注册失败', res.data.error);
    }
  } catch (err) {
    console.log('请求失败', err);
  }
};

</script>

<style lang="less" scoped>
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.sign_form {
  flex: 1;
  padding: 100px 30px 150px 30px;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  h1 {
    font-size: 3rem;
    max-width: 1000px;
    text-align: center;
    // text-shadow: -1px -1px 0px #00b0b997, 1px 1px 0px #b9002b97;
  }
  h3 {
    text-align: center;
    background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
    background-size: 400% 400%;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    animation: gradient 4s ease infinite;
    text-shadow: 0px 0px 2px #c471ed7a;
  }
  .input_zone {
    width: 50%;
    .twoinone_Line {
      display: flex;
      justify-content: center;
      gap: 10px;
      .input_text {
        flex: 1;
        input {
          width: 100%;
        }
      }
    }

    .input_text {
      margin-bottom: 20px;
      flex: 1;
      input {
        width: 100%;
      }
    }
  }
}
.nextStep {
  background-color: #023047;
  color: white;
  width: 50%;
  text-align: center;
  height: 50px;
  line-height: 50px;
  border-radius: 10px;
  p{
    text-align: center;
  }
}

.Progress_bar {
  width: 100%;
  background-color: rgba(128, 128, 128, 0.11);
  width: 50%;
  height: 20px;
  border-radius: 20px;
  margin: 20px 0;
  .progress {
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #1d8fec, #c471ed, #f64f59);
    background-size: 400% 100%;
    border-radius: 20px;
    animation: gradient 5s ease infinite;
  }
}
input {
  height: 50px;
  border-radius: 10px;
  border: 1px solid rgba(83, 83, 83, 0.062);
  background-color: rgba(212, 212, 212, 0.062);
  box-sizing: border-box;
  padding: 20px;
  flex-shrink: 0;
}
</style>
