<template>
  <div class="sign_form">
    <h1>Let the world know who you are</h1>
    <h3>Unleash Your Potential, Ignite Your Spark</h3>

    <!-- Progress bar -->
    <div class="Progress_bar">
      <div class="progress"></div>
    </div>

    <!-- form zone -->
    <div class="grid-container">
      <div class="tab_title">
        <div class="header-title">Platform</div>
        <div class="header-title">Home page link</div>
      </div>

      <div class="form_contain">
        <div class="platform-list">
          <div>Instagram</div>
          <div>YouTube</div>
          <div>Twitter</div>
          <div>Twitch</div>
          <div>Facebook</div>
        </div>
        <div class="input-fields">
          <input v-model="form.instagram" type="text" placeholder="Link">
          <input v-model="form.youTube" type="text" placeholder="Link">
          <input v-model="form.twitter" type="text" placeholder="Link">
          <input v-model="form.twitch" type="text" placeholder="Link">
          <input v-model="form.facebook" type="text" placeholder="Link">
        </div>
      </div>
    </div>

    <!-- 下一步按钮 -->
    <div class="nextStep" @click="to_step_4">
      <p>Next</p>
    </div>
  </div>
</template>

<script setup>
import { ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';
const axios = inject('$axios');

const router = useRouter();

const form = ref({
  instagram: '',
  youTube: '',
  twitter: '',
  twitch: '',
  amazon: ''
});

onMounted(async () => {
     //判断用户步骤
     try{const step_res = await axios.get('/api/client/broader-user/get-user-state') 
    console.log(step_res.data.data.regState);
    if(step_res.data.data.regState != 1){
        switch (step_res.data.data.regState){
            case 1 :{router.push('/signupform_2');return;}
            case 2 :{router.push('/signupform_3');return;}
            case 3 :{router.push('/signupform_4');return;}
        }
    }}
    catch{
        router.push('/signup');
    }
})

const to_step_4 = async () => {
  console.log(form.value);

  try {
    const res = await axios.post('/api/client/broader-user/reg-third', form.value);
    console.log(res.data);
    if (res.data.success) {
      router.push('/signupform_4');
    } else {
      console.error('用户第三步注册失败', res.data.error);
    }
  } catch (err) {
    console.log('请求失败', err);
  }
};
</script>

<style scoped lang="less">
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.nextStep {
  background-color: #023047;
  color: white;
  width: 50%;
  text-align: center;
  height: 50px;
  line-height: 50px;
  border-radius: 10px;
  margin-top: 40px;
  p{
    text-align: center;
  }
}

.Progress_bar {
  width: 100%;
  background-color: rgba(128, 128, 128, 0.11);
  width: 50%;
  height: 20px;
  border-radius: 20px;
  margin: 20px 0;

  .progress {
    width: 66%;
    height: 100%;
    background: linear-gradient(to right, #1d8fec, #c471ed, #f64f59);
    background-size: 400% 100%;
    border-radius: 20px;
    animation: gradient 5s ease infinite;
  }
}

.sign_form {
  flex: 1;
  padding: 100px 30px 50px 30px;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  h1 {
    font-size: 3rem;
    max-width: 1000px;
    text-align: center;
    // text-shadow: -1px -1px 0px #00b0b997, 1px 1px 0px #b9002b97;
  }

  h3 {
    text-align: center;
    background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
    background-size: 400% 400%;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    animation: gradient 4s ease infinite;
    text-shadow: 0px 0px 2px #c471ed7a;
  }
}

input {
  height: 50px;
  border-radius: 10px;
  border: 1px solid rgba(83, 83, 83, 0.062);
  background-color: rgba(212, 212, 212, 0.062);
  box-sizing: border-box;
  padding: 20px;
  flex-shrink: 0;
}

.grid-container {
  width: 60%;
  height: 400px;
}

.tab_title {
  display: flex;
  justify-content: space-around;
  font-weight: bolder;
}

.form_contain {
  display: flex;
  justify-content: space-around;
  height: 100%;
}

.platform-list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  div {
    font-weight: bolder;
  }
}

.input-fields {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>