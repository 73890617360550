<template>
  <!-- 导航栏 -->
  <!-- <div class="nav">
    <p class="logo">LOGO</p>
    <a href="#" class="join_btn">Join Us</a>
  </div> -->
<my-nav></my-nav>
  <!-- 页面标题 -->
  <div class="page_title">
    <h1>Broader Blog</h1>
  </div>

  <hr class="hr" />

  <!-- 内容 -->
  <div class="contain">
    <div class="contain_title">Featured</div>

    <!-- 文章区域 -->
    <div class="art_zone" >
      <div class="art_card" v-for="(item, index) in blogs" :key="index" @click="to_article(item.id)">
        <div class="art_pic">
          <img :src="item.imgUrl" alt="" srcset="">
        </div>
        <div class="art_text">
          <h3>{{item.title}}</h3>
          <p>
            {{item.summary}}
          </p>
        </div>
      </div>
    </div>
  </div>

  <my-footer></my-footer>
</template>


<script setup>

import { ref, onMounted, reactive, inject,onBeforeUnmount,computed,defineEmits } from "vue";
import { useRouter } from 'vue-router'; 
const axios = inject("$axios");
import myNav from '../components/myNav.vue'

let blogs = ref()

const router = useRouter(); // 获取 router 实例  
onMounted(async ()=>{
  const blog_list = await axios.get('/api/client/blog/get-blog-list')
  // console.log(blog_list.data.data);
  blogs.value = blog_list.data.data
  console.log(blogs);
})
// let test_arr =  reactive([
//   1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
//   23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
// ]);

const to_article = (id) => {
  console.log(id);
  router.push({ path: '/article', query: { id:id } })
};
</script>


<style scoped lang="less">
.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
  position: fixed;
  width: 100vw;
  top: 0;
  background-color: rgba(249, 249, 249, 0.776);
  backdrop-filter: blur(15px);
  z-index: 10; /* 设置导航栏在最上层 */
}

.logo {
  font-weight: bolder;
  font-size: 30px;
}

.join_btn {
  text-decoration: none;
  color: #fff;
  background-color: #023047;
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50px;
}

.page_title {
  box-sizing: border-box;
  width: 100vw;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.hr {
  display: block;
  width: 80vw;
  margin: 0 auto;
  border-top-color: rgba(128, 128, 128, 0.194);
}

.contain {
  width: 80vw;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 30px 0;

  .contain_title {
    font-size: 30px;
  }

  .art_zone {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }

  .art_card {
    margin-top: 40px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 10px 30px;
    padding-top: 20px;
    border-radius: 10px;
    transition: transform 0.2s;
    z-index: 1; /* 确保卡片层级低于导航栏 */
    background-color: #ededed41;
    box-shadow: 0 0 30px 0px #c1c1c162;
    display: flex;
    flex-direction: column;
    gap: 2vh;

    &:hover {
      transform: scale(1.05);
      z-index: 9; /* 卡片悬浮时提高层级，但仍低于导航栏 */
    }

    .art_pic {
      width: 260px;
      height: 200px;
      background-color: #3b2525;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
      }
    }

    .art_text {
      padding: 10px;
      text-align: center;
      display: flex;
      gap: 20px;
      flex-direction: column;
      text-align:left!important;
      height: 100%;
      p{
        text-align: left!important;
        word-break:break-all!important;
      }
    }
  }
}

.footer {
  height: 200px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  padding: 10px 200px;

  .Email_zone {
    font-size: 30px;
  }
}
</style>
