import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import myNav from './components/myNav.vue'
import myFooter from './components/myFooter.vue'
// import 'amfe-flexible '
import axios from './axios';
import setTitleMixin from './mixin/setTitleMixin'


// 全局element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueGtag from "vue-gtag";

// md-edit
// 预览组件以及样式
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
// VuePress主题以及样式（这里也可以选择github主题）
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

// Prism
import Prism from 'prismjs';
// 代码高亮
import 'prismjs/components/prism-json';
// 选择使用主题
VMdPreview.use(vuepressTheme, {
  Prism,
});

const app = createApp(App)

app.provide("$axios", axios);// 将 Axios 添加到全局属性
app.mixin(setTitleMixin)
app.component('myNav', myNav) 
app.component('myFooter', myFooter) 
app.use(store).use(ElementPlus).use(router).use(VMdPreview).use(VueGtag, {
    config: { id: "G-5CS6Y8515Y" }
  }).mount('#app')
