function getRandom(option) {
  const random = Math.random;
  const result = {
    x: option.x * random(), // 花瓣的初始 x 坐标
    y: -option.y * random(), // 花瓣的初始 y 坐标，调整为从屏幕顶部生成
    s: option.minSize + (option.maxSize - option.minSize) * random(), // 花瓣的初始大小，基于最小和最大大小范围
    // a: option.a * random(), // 花瓣的初始透明度
    a: option.a * (0.2+random()), // 花瓣的初始透明度
    r: option.r * random(), // 花瓣的初始旋转角度
    v: option.minSpeed + (option.maxSpeed - option.minSpeed) * random(), // 初始速度
    deceleration: option.minDeceleration + (option.maxDeceleration - option.minDeceleration) * random() // 减速度
  };
  return result;
}

// Sakura 类，表示单个花瓣
class Sakura {
  constructor(option) {
    this.canvas = option.canvas;
    this.ctx = option.canvas.getContext('2d');
    const random = getRandom(option);
    this.x = random.x;
    this.y = random.y;
    this.s = random.s; // 花瓣大小，可调整初始大小范围
    this.a = random.a;
    this.r = random.r;
    this.v = random.v; // 初始速度
    this.deceleration = random.deceleration; // 减速度
    this.minMoveSpeed = option.minMoveSpeed; // 最低移动速度
    this.color = `rgba(${Math.floor(255 * Math.random())}, ${Math.floor(255 * Math.random())}, ${Math.floor(255 * Math.random())}, ${this.a})`; // 随机颜色
    this.rotate = option.rotate;
    this.rotate.value = option.rotate.value;
    this.rotate.step = option.rotate.step;
  }

  // 绘制花瓣的方法
  draw() {
    this.ctx.save();
    const dx = this.x,
      dy = this.y,
      s = this.s,
      r = this.r;
    this.ctx.translate(dx, dy);
    this.ctx.rotate(r);
    this.ctx.beginPath();
    this.ctx.moveTo(0, 0);
    this.ctx.lineTo(0, -s);
    this.ctx.lineTo(s, -s / 3);
    this.ctx.lineTo(s, s / 3);
    this.ctx.lineTo(0, s);
    this.ctx.lineTo(-s, s / 3);
    this.ctx.lineTo(-s, -s / 3);
    this.ctx.lineTo(0, -s);
    this.ctx.closePath();
    this.ctx.fillStyle = this.color; // 使用随机颜色
    this.ctx.fill();
    this.ctx.restore();
  }

  // 更新花瓣的位置和旋转角度的方法
  update() {
    if (this.v > this.minMoveSpeed) {
      this.v -= this.deceleration; // 逐步减速，但不完全停止
    }
    if (this.v < this.minMoveSpeed) {
      this.v = this.minMoveSpeed; // 保持最低移动速度
    }
    this.y += this.v; // 花瓣下落速度
    if (this.y > this.canvas.height) {
      this.y = -this.s; // 从顶部重新生成花瓣
    }
    this.r += this.rotate.step;
    this.draw();
  }
}

// SakuraList 类，用于管理多个花瓣
class SakuraList {
  constructor() {
    this.list = [];
  }

  push(sakura) {
    this.list.push(sakura);
  }

  update() {
    for (let i = 0, len = this.list.length; i < len; i++) {
      this.list[i].update();
    }
  }

  draw() {
    for (let i = 0, len = this.list.length; i < len; i++) {
      this.list[i].draw();
    }
  }
}


function fadeOut(el, duration = 3000) {  
  let start = null;  
  const opacityTarget = 0;  
  const frame = (timestamp) => {  
      if (!start) start = timestamp;  
      const progress = Math.min((timestamp - start) / duration, 1);  
      el.style.opacity = 1 - progress;  
      if (progress < 1) {  
          window.requestAnimationFrame(frame);  
      } else {  
          el.style.opacity = opacityTarget; // 确保最终透明度为0  
          document.body.removeChild(el);
      }  
  };  
  window.requestAnimationFrame(frame);  
}  


// flowerDropPink 函数，用于初始化并开始花瓣飘落效果
// 添加参数：numFlowers - 花瓣数量, dropDuration - 下落时间, minSize - 最小花瓣大小, maxSize - 最大花瓣大小, minSpeed - 最小初始速度, maxSpeed - 最大初始速度, minDeceleration - 最小减速度, maxDeceleration - 最大减速度, minMoveSpeed - 最低移动速度
export function flowerDropPink(numFlowers = 150, dropDuration = 3500, minSize = 3, maxSize = 7, minSpeed = 2, maxSpeed = 15, minDeceleration = 0.01, maxDeceleration = 0.05, minMoveSpeed = 3) {
  const canvas = document.createElement('canvas');
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
  canvas.setAttribute('style', 'position:fixed;top:0;left:0;pointer-events:none;z-index:999999');
  document.body.appendChild(canvas);

  const ctx = canvas.getContext('2d');
  const sakuraList = new SakuraList();
  const rotate = { value: 0, step: 0.01 };

  // 创建多个花瓣
  let flowerCount = 0;
  const maxFlowers = numFlowers;
  const startTime = Date.now();

  let count = 0
  function createFlowers() {
    const elapsedTime = Date.now() - startTime;
    if (elapsedTime > dropDuration) {
      //移除花瓣
      fadeOut(canvas);
      console.log(1);
      return;
    }
    if (flowerCount < maxFlowers) {
      const sakura = new Sakura({
        canvas: canvas,
        x: canvas.width,
        y: canvas.height,
        minSize: minSize, // 最小花瓣大小
        maxSize: maxSize, // 最大花瓣大小
        a: 1,
        r: 6,
        rotate: rotate,
        minSpeed: minSpeed, // 最小初始速度
        maxSpeed: maxSpeed, // 最大初始速度
        minDeceleration: minDeceleration, // 最小减速度
        maxDeceleration: maxDeceleration, // 最大减速度
        minMoveSpeed: minMoveSpeed // 最低移动速度
      });
      sakuraList.push(sakura);
      flowerCount++;
    }

    requestAnimationFrame(createFlowers);
    console.log(    count++);


  }

  createFlowers();
  // 渲染并更新花瓣位置
  function render() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    sakuraList.update();
    requestAnimationFrame(render);
  }
  render();
}