<template>
  <!-- 基本信息修改 -->
  <div class="basic">
    <h1 class="basic_title">Basic information</h1>
    <!-- Basic信息修改两侧 -->
    <div class="basic_box_flex">
      <!-- 电话等 -->
      <div class="basic_box">
        <div class="basic_name">
          <p>Artist Name</p>
          <p>Email</p>
          <p>Location</p>
          <p>Followers</p>
        </div>
        <div class="links_box">
          <input type="text" v-model="user_info_body.artistName" />
          <input type="text" v-model="user_info_body.email" />
          <!-- <input type="text" v-model="user_info_body.countryCode" /> -->
          <!-- 国家列表 -->
          <select v-model="user_info_body.countryCode" placeholder="Country">
            <option value="" disabled selected>Country</option>
            <option
              :value="item.key"
              v-for="(item, index) in country_list"
              :key="index"
            >
              {{ item.value }}
            </option>
          </select>
          <select v-model="user_info_body.followers">
            <option
              :value="item.key"
              v-for="(item, index) in followers_list"
              :key="index"
              :selected="user_info_body.followers == item.value"
            >
              {{ item.value }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <!-- 标签修改 -->
  <div class="tag_zone">
    <h1 class="tag_title">Tags</h1>
    <div class="tags">
      <div
        class="edit_tag"
        v-for="(item, index) in tags"
        :key="index"
        @click="item.selected = !item.selected"
        :class="{ selected: item.selected }"
      >
        {{ item.value }}
      </div>
    </div>
  </div>

  <div class="other_info_zone">
    <div class="platform">
      <div class="platform_title_box">
        <h1 class="platform_title">Platform</h1>
        <h4>Please put a link to the homepage</h4>
      </div>
      <div class="platform_box">
        <div class="platform_name">
          <p>Instagram</p>
          <p>YouTube</p>
          <p>Twitter</p>
          <p>Twitch</p>
          <p>Facebook</p>
        </div>
        <div class="links_box">
          <input type="text" v-model="user_info_body.instagram" />
          <input type="text" v-model="user_info_body.youTube" />
          <input type="text" v-model="user_info_body.twitter" />
          <input type="text" v-model="user_info_body.twitch" />
          <input type="text" v-model="user_info_body.facebook" />
        </div>
      </div>
    </div>
    <div class="dis_zone">
      <textarea
        v-model="user_info_body.description"
        placeholder="Describe your account. For example: I am a TikToker specializing in family video content. My videos cover various aspects of family life, including family activities, daily moments, and parent-child interactions. Through my videos, I share joyful moments of family life , aiming to bring happiness and inspiration to my audience"
      ></textarea>
    </div>
  </div>

  <!-- 其他联系方式 -->
  <div class="other_con">
    <h1 class="other_con_title">Other Contacts</h1>
    <div class="other_con_box">
      <div class="other_con_name">
        <p>Phone</p>
        <p>WhatsApp</p>
        <p>SnapChat</p>
        <p>Telegram</p>
      </div>
      <div class="links_box">
        <input type="text" v-model="user_info_body.moblie" />
        <input type="text" v-model="user_info_body.whatsApp" />
        <input type="text" v-model="user_info_body.snapchat" />
        <input type="text" v-model="user_info_body.telegram" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive, inject,onBeforeUnmount,computed,defineEmits } from "vue";
const axios = inject("$axios");
import { ElNotification } from 'element-plus'

let user_info_body = reactive({
  artistName: "",
  email: "",
  countryCode: "",
  followers: "",
  instagram: "",
  youTube: "",
  twitter: "",
  twitch: "",
  amazon: "",
  description: "",
  moblie: "",
  whatsApp: "",
  snapchat: "",
  telegram: ""
});

let tags = ref([
]);
const country_list = ref([]);
const followers_list = ref([]);

//跟新用户选项
function updateSelectedStatus(arr, keys) {
    // 将 keys 转换为 Set 以便快速查找
    let keySet = new Set(keys);

    // 遍历 array1 更新 selected 属性
    arr.forEach(obj => {
        if (keySet.has(obj.key.toString())) {
            obj.selected = true;
        } else {
            obj.selected = false;
        }
    });

    return arr;
}

// 监听并且实时更新 tags
let realtime_tags = computed(() => {
    return tags.value
        .filter(type => type.selected)
        .map(type => type.key);
});

onMounted(async () => {
  // 获取用户的参数
  const res = await axios.get("/api/client/broader-user/get-user-profile");
  Object.assign(user_info_body, res.data.data);
  // 地区列表获取
  try {
    const country_list_res = await axios.get('/api/client/data/get-nation-list');
    country_list.value = country_list_res.data.data;
  } catch (error) {
    console.error('Failed to load country list', error);
  }
  // 粉丝数量列表获取
  try {
    const followers_list_res = await axios.get('/api/client/data/get-number-of-followers');
    followers_list.value = followers_list_res.data.data;
  } catch (error) {
    console.error('Failed to load follower list', error);
  }
  // 账号类型列表获取
  try {
      const tags_list_res = await axios.get('/api/client/data/get-tag-list');
      // 把请求来的数值转化成可用的数组
      tags.value = tags_list_res.data.data.map(item => ({...item,selected:false}))
      tags.value = updateSelectedStatus(tags.value,user_info_body.tags)
      // console.log(tags);
  } catch (error) {
      console.error('Failed to load tags list', error);
  }
});

//注册子传父事件
const emitEvents = defineEmits(['Edit_state'])
// 在组件销毁之前执行的清理操作
onBeforeUnmount(async () => { 
  // 可以在这里进行清理操作，例如取消异步请求、清除定时器等
  // console.log(user_info_body);
    user_info_body.tags  = realtime_tags
  // console.log(user_info_body.artistName , user_info_body.email , user_info_body.countryCode , user_info_body.followers , user_info_body.description , user_info_body.moblie , user_info_body.description , user_info_body.artistName , !user_info_body.tags.length == 0 );
  if (user_info_body.artistName && user_info_body.email && user_info_body.countryCode && user_info_body.followers && user_info_body.description && user_info_body.moblie && user_info_body.description && user_info_body.artistName && ! user_info_body.tags.length == 0 ){
    const res = await axios.put('/api/client/broader-user/update-user-profile',user_info_body)
    console.log(res);
    if(res.data.success){
      ElNotification({
        message: 'Edit Success',
        type: 'success',
        customClass: 'cus_class',
        offset: 80,
        duration:7000,
      })
    }
    else{
      ElNotification({
        message: res.data.msg,
        type: 'erro',
        customClass: 'cus_class',
        offset: 80,
        duration:7000,
      })
    }
  }
  else{
    ElNotification({
        message: 'Edit Fail! Check Your Input',
        type: 'error',
        customClass: 'cus_class',
        offset: 80,
        duration:7000,
      })

      return false
  }

});

</script>

<style lang="less">
.tag_zone {
  margin-top: 20px;

  .tags {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;

    .edit_tag {
      background-color: rgba(251, 148, 3, 0.549);
      padding: 10px 20px;
      border-radius: 50px;
      font-weight: bolder;
      color: rgb(255, 255, 255);
      transition: all 0.5s ease-in-out;
      cursor: pointer;
    }
  }
}

.other_info_zone {
  display: flex;
  padding-top: 30px;
  width: 100%;
  gap: 20px;
  min-height: 200px;
}

.platform_title_box {
  display: flex;
  align-items: baseline;
  margin: 0 0 30px 0;
  gap: 20px;

  h4 {
    color: gray;
  }
}

.platform_title {
  margin: 0;
}

.platform {
  border: 1px solid rgba(166, 166, 166, 0.28);
  width: 60%;
  height: 320px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 18px;
  box-shadow: 0 4px 8px rgba(145, 145, 145, 0.171);
  p {
    height: 22px;
    line-height: 22px;
  }
}

.platform_box {
  display: flex;
  gap: 40px;
  justify-content: space-around;

  div {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.dis_zone {
  width: 40%;
  height: 320px;
  display: flex;

  textarea {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 20px;
    line-height: 20px;
    border-radius: 10px;
    border: 1px solid rgba(83, 83, 83, 0.062);
    background-color: rgba(212, 212, 212, 0.062);
    resize: none;
  }
}

.other_con_title {
  margin: 30px 0 30px 0;
}

.other_con {
  width: 60%;
  height: 320px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 18px;
}

.other_con_box {
  display: flex;
  gap: 40px;

  div {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.basic_box_flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 80px;
}

.basic_title {
  margin: 30px 0 30px 0;
}

.basic {
  width: 60%;
  height: 320px;
  box-sizing: border-box;
  padding: 20px 0;
  border-radius: 18px;
}

.basic_box {
  display: flex;
  gap: 10px;

  div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 140px;
  }
}

input {
  background-color: #aaaaaa2f;
  height: 22px;
  border-radius: 20px;
  padding: 0 20px;
}

select,
select:focus {
  background-color: #aaaaaa2f;
  height: 22px;
  border-radius: 20px;
  padding: 0 20px;
  border: none;
}

.basic_name {
  p {
    height: 22px;
    line-height: 22px;
  }
}
.selected {
  background-color: rgb(251, 148, 3) !important;
}
</style>
