<template>
  <div class="sign_form">
    <h1>Let the world know who you are</h1>
    <h3>Unleash Your Potential, Ignite Your Spark</h3>

    <!-- Progress bar -->
    <!-- <div class="Progress_bar">
      <div class="progress"></div>
    </div> -->

    <!-- 输入区域 -->
    <div class="input_zone">
      <!-- 第一行输入 -->

      <div class="input_text">
        <!-- 输入名称的提示信息（目前为空） -->
        <p class="input_name"></p>
        <input type="text" placeholder="Email" v-model="email" />
      </div>

      <div class="twoinone_Line">
        <div class="input_text">
          <!-- 输入名称的提示信息（目前为空） -->
          <p class="input_name"></p>
          <!-- 艺术家名称输入框 -->
          <input type="text" placeholder="Verification Code" v-model="code" />
        </div>

        <div class="getCode" @click="debouncedGetCode"> Get verification code </div>

      </div>

    </div>


    <!-- 下一步按钮 -->
    <div class="nextStep" @click="toStep2()">
      <p>Next</p>
    </div>


  </div>
</template>

<script setup>
import { inject, ref } from 'vue';
import { useRouter } from 'vue-router';
import { ElNotification } from 'element-plus';
import { debounce } from 'lodash';  // 引入 lodash 的 debounce 方法

const router = useRouter();
const axios = inject('$axios'); // 正确注入 axios 实例

let email = ref('');
let code = ref('');

const getCode = async () => {
  try {
    const requestData = {
      email: email.value,
    };
    const res = await axios.post('/api/client/broader-user/login', requestData);

    if (res.data.success) {
      ElNotification({
        message: 'Code sent',
        type: 'success',
        customClass: 'cus_class',
        offset: 80,
      });
    } else {
      ElNotification({
        message: res.data.msg,
        type: 'warning',
        customClass: 'cus_class',
        offset: 80,
      });
    }

  } catch (error) {
    console.error('Error fetching verification code:', error);
  }
};

// 使用 debounce 方法包装 getCode
const debouncedGetCode = debounce(getCode, 1000);

const toStep2 = async () => {
  try {
    const req = {
      email: email.value,
      code: code.value
    };
    var res = await axios.post('/api/client/broader-user/verify-email', req);

    // 保存 token
    if (res.data.data.isVerify) {
      localStorage.setItem('authToken', res.data.data.token);
      //开始分流用户
      console.log(res.data);
      if (res.data.data.regStep == -1) {
        router.push('/user_info');
      } else if (res.data.data.regStep == 1) {
        router.push('/signupform_2');
      } else if (res.data.data.regStep == 2) {
        router.push('/signupform_3');
      } else if (res.data.data.regStep == 3) {
        router.push('/signupform_4');
      }
    } else {
      ElNotification({
        message: 'Email or verification code is incorrect!',
        type: 'warning',
        customClass: 'cus_class',
        offset: 80,
      });
    }
  } catch (err) {
    console.log(err);
    ElNotification({
        message: 'Check your input',
        type: 'warning',
        customClass: 'cus_class',
        offset: 80,
      });
  }
};
</script>

<style lang="less" scoped>
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.sign_form {
  flex: 1;
  padding: 100px 30px 150px 30px;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  h1 {
    font-size: 3rem;
    max-width: 1000px;
    text-align: center;
    // text-shadow: -1px -1px 0px #00b0b997, 1px 1px 0px #b9002b97;
  }

  h3 {
    text-align: center;
    background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
    background-size: 400% 400%;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    animation: gradient 4s ease infinite;
    text-shadow: 0px 0px 2px #c471ed7a;
  }

  .input_zone {
    width: 50%;

    .twoinone_Line {
      display: flex;
      gap: 10px;

      .input_text {
        flex: 1;

        input {
          width: 100%;
        }
      }
    }

    .input_text {
      margin-bottom: 20px;
      flex: 1;

      input {
        width: 100%;
      }
    }
  }
}

.nextStep {
  background-color: #023047;
  color: white;
  width: 50%;
  text-align: center;
  height: 50px;
  line-height: 50px;
  border-radius: 10px;
  p{
    text-align: center;
  }
}


.Progress_bar {
  width: 100%;
  background-color: rgba(128, 128, 128, 0.11);
  width: 50%;
  height: 20px;
  border-radius: 20px;
  margin: 20px 0;

  .progress {
    width: 25%;
    height: 100%;
    background: linear-gradient(to right, #1d8fec, #c471ed, #f64f59);
    background-size: 400% 100%;
    border-radius: 20px;
    animation: gradient 5s ease infinite;
  }
}

input,
select {
  height: 50px;
  border-radius: 10px;
  border: 1px solid rgba(83, 83, 83, 0.062);
  background-color: rgba(212, 212, 212, 0.062);
  box-sizing: border-box;
  padding: 20px;
  flex-shrink: 0;
}

.getCode {
  background-color: #00aaff;
  font-size: 12px;
  color: white;
  width: 50%;
  text-align: center;
  height: 50px;
  line-height: 50px;
  border-radius: 10px;
}
.getCode:active {
  background-color: #086da0;
}
</style>

<style>
.cus_class {
  background-color: rgba(255, 255, 255, 0.497) !important;
  border-radius: 20px !important;
  backdrop-filter: blur(15px) !important;
  -webkit-backdrop-filter: blur(30px) !important;
  border: none !important;
}
</style>
