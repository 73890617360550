<template>
  <div class="main_view">

  <router-view/>
  </div>

</template>

<style lang="less">
@font-face {
    font-family: Basic;
    src: url('./assets/font/Luciole-Regular-2.ttf');
}
*{
  margin: 0;
  padding: 0;
  font-family: Basic;
}
/* 移除所有input的内外边距和边框 */  
input {  
  user-select:none;
  margin: 0;  
  padding: 0;  
  border: none;  
  /* 移除input的默认背景色 */  
  background-color: transparent;  
  /* 移除input的默认边框阴影 */  
  box-shadow: none;  
  /* 移除input的默认轮廓（focus时的边框） */  
  outline: none;  
  /* 移除input的默认字体样式（可选） */  
  font-family: inherit;  
  font-size: inherit;  
  color: inherit;  
  /* 移除input的默认内填充（一些浏览器可能会有默认的内填充） */  
  -webkit-padding-start: 0; /* Chrome, Safari, Edge */  
  -webkit-padding-end: 0; /* Chrome, Safari, Edge */  
  /* 移除input的默认圆角（一些浏览器可能会有默认的圆角） */  
  border-radius: 0;  
  /* 移除input的默认滚动条（对于textarea或可滚动的input） */  
  overflow: visible;  
  /* 移除input的默认动画和过渡效果（如果存在） */  
  transition: none;  
  animation: none;  
  /* 移除input的默认垂直对齐方式（可选） */  
  vertical-align: baseline;  
  /* 更多可能的默认样式移除... */  
}  
  
/* 如果你想针对特定的input类型（如text, password, submit等）做进一步处理，可以添加更多的选择器 */  
input[type="text"],  
input[type="password"],  
input[type="submit"] {  
  /* 这里可以添加针对特定类型的input的样式 */  
}  
  
/* 注意：outline: none; 可能会影响到可访问性，因为对于键盘用户来说，焦点轮廓是一个重要的视觉提示。  
   因此，你可能需要为键盘用户提供一个替代的视觉提示，如使用box-shadow来模拟轮廓效果。 */  
  
/* 示例：为键盘用户提供一个模拟的轮廓效果 */  

.main_view{
   overflow-x: hidden
  // position: fixed;
}
* {  
  
    /* 滚动条样式设置 */  
    ::-webkit-scrollbar {  
        width: 3px; /* 滚动条宽度 */  
        height: 10px; /* 滚动条高度（针对水平滚动条） */  
    }  
  
    ::-webkit-scrollbar-thumb {  
        background-color: rgba(128, 128, 128, 0.582);/* 滑块背景色渐变 */  
        border-radius: 50px; /* 滑块边角 */  
    }  
  
    ::-webkit-scrollbar-track {  
        background-color: #f5f5f500; /* 轨道背景色 */  
        border: 1px solid #cccccc00; /* 轨道边框 */  
    }  
    //user-select: none;
}

html {
    font-size: 16px; /* 默认字体大小 */
}

/* 当视口宽度小于 1200px 时，调整根元素字体大小 */
@media (max-width: 1200px) {
    html {
        font-size: 15px;
    }
}

/* 当视口宽度小于 992px 时，进一步调整根元素字体大小 */
@media (max-width: 992px) {
    html {
        font-size: 14px;
    }
}

/* 当视口宽度小于 768px 时，进一步调整根元素字体大小 */
@media (max-width: 768px) {
    html {
        font-size: 13px;
    }
}

/* 当视口宽度小于 576px 时，进一步调整根元素字体大小 */
@media (max-width: 576px) {
    html {
        font-size: 12px;
    }
}

</style>
