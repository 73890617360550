<template>
  <!-- 这是一个自定义的导航组件 -->
  <my-nav></my-nav>

  <!-- 签名区域 -->
  <div class="sign_zone">
    <!-- 图片区域 -->
    <div class="sign_pic">
      <!-- 引用自阿尔伯特·爱因斯坦的名言 -->
      <h1>
        Life is like riding a bicycle. To keep your balance, you must keep
        moving
      </h1>
      <h3>— Albert Einstein</h3>
    </div>

    <!-- 表单区域 -->
    <div class="form_view">
      <router-view />
    </div>

  </div>

</template>

<script setup>

</script>

<style lang="less" >
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

// 多端适配开始
/* 超大屏幕 */
@media (min-width: 1200px) {

}

/* 大屏幕 */
@media (min-width: 990px) and (max-width: 1199px) {
}

/* 中屏幕 */
@media (min-width: 768px) and (max-width: 991px) {
}

/* 小屏幕 */
@media (min-width: 576px) and (max-width: 767px) {
}

/* 超小屏幕 */
@media (max-width: 575px) {
.sign_pic{
  display: none!important;
}
.form_view{
  width: 100vw!important;
}
.input_zone{
  width: 100%!important;
}
.sign_form{
  width: 100%!important;
}
.grid-container{
  width: 100%!important;
}
}

// 多端适配结束

.sign_zone {
  display: flex;
  flex: 1;
  height: 100vh;
  align-items: center;
}

.sign_pic {
  background-image: url("../assets/sighUp_pic.jpg");
  height: 100vh;
  width: 40vw;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
  gap: 30px;
  position: relative;
  z-index: 1;

  h1 {
    font-size: 2rem;
    color: aliceblue;
  }

  h3 {
    width: 100%;
    color: aliceblue;
    text-align: right;
  }
}

.sign_pic::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.39);
  /* 半透明的黑色覆盖层 */
  z-index: -1;
  /* 确保覆盖层在背景图片上面 */
}

.form_view {
  height: 100vh;
  overflow-y: scroll;
  box-sizing: border-box;
  width: 60vw;
  display: flex;
}
</style>
