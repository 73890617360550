<template>
  <my-nav></my-nav>
  <div class="userBanner">
    <h1>Unlock Your Influence. <a class="trans">Start Earning Today</a></h1>
    <h2>
      <a class="trans">Join thousands of influencers</a> who are monetizing
      their impact with ease
    </h2>
  </div>

  <div class="btn_zone">
    <!-- <div class="btn login" @click="to_login()">Log in</div> -->
    <div class="btn signUp" @click="to_sighUp()">Sign Up</div>
  </div>
</template>
<script setup>
import { ref, onMounted, inject, onUpdated } from "vue";
import { useRouter } from 'vue-router';

const axios = inject("$axios");
const router = useRouter();
let user_info_body = ref({});
onMounted(async () => {
  try {
    const res = await axios.get("/api/client/broader-user/get-user-profile");
    user_info_body.value = res.data.data;
    //过滤认证环节
    // 未审核
    if (user_info_body.value.auditStatus == 1) {
      router.push('/user_info')
    }
  }
  catch {

  }
})



const to_sighUp = () => {
  router.push('/signup')
}
const to_login = () => {
  router.push('/login')
}
</script>

<style lang="less" scoped>
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}


// 多端适配开始
/* 超大屏幕 */
@media (min-width: 1200px) {}

/* 大屏幕 */
@media (min-width: 990px) and (max-width: 1199px) {}

/* 中屏幕 */
@media (min-width: 768px) and (max-width: 991px) {}

/* 小屏幕 */
@media (min-width: 576px) and (max-width: 767px) {}

/* 超小屏幕 */
@media (max-width: 575px) {
  .btn_zone {
    flex-direction: column;
  }
}

// 多端适配结束


.userBanner {
  box-sizing: border-box;
  margin-top: 6.25rem;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 20px;
  margin-bottom: 80px;
  text-align: center;

  h1 {
    margin-bottom: 30px;
    font-size: 4rem;
  }

  h1 {
    font-size: 4em;
    text-align: center;
    // text-shadow: -1px -1px 0px #00b0b997, 1px 1px 0px #b9002b97;
  }

  .trans {
    display: inline;
    font-size: inherit;
    text-align: center;
    background: linear-gradient(45deg,
        #0d68fa,
        #6929ff,
        #6929ff,
        #6dd5fa,
        #164361);
    background-size: 400% 400%;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    animation: gradient 3s ease infinite;
    text-shadow: 0px 0px 2px #65bbf0bb;
  }
}

.btn_zone {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  user-select: none;

  .btn {
    // background-color: antiquewhite;
    width: 250px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bolder;
  }

  .login {
    background-color: #fff;
    border: 1px solid rgba(128, 128, 128, 0.336);
  }

  .signUp {
    background-color: #0d68fa;
    border: 1px solid rgba(128, 128, 128, 0.336);
    color: #ffffff;
    transition: 0.09s ease-in-out;
  }

  .signUp:hover {
    background-color: #034fc9;
  }

  .signUp:active {
    background-color: #2578fd;
    box-shadow: 0 0 10px #0d68fa;
  }
}
</style>