<template>
  <!-- 这是一个自定义的导航组件 -->
  <my-nav></my-nav>

  <!-- 签名区域 -->
  <div class="sign_zone">
    <!-- 图片区域 -->
    <div class="sign_pic">
      <!-- 引用自阿尔伯特·爱因斯坦的名言 -->
      <h1>
        The joy of life depends on life itself, not on work or location.
      </h1>
      <h3>— Ralph Waldo Emerson</h3>
    </div>

    <!-- 表单区域 -->
    <div class="login_form">
      <h1>Welcome home</h1>
      <h3>Unleash Your Potential, Ignite Your Spark</h3>

      <!-- 输入区域 -->
      <div class="input_zone">
        <!-- 第一行输入 -->

        <div class="input_text">
          <!-- 输入名称的提示信息（目前为空） -->
          <p class="input_name"></p>
          <input type="email" placeholder="Email" />
        </div>

        <div class="input_text">
          <!-- 输入名称的提示信息（目前为空） -->
          <p class="input_name"></p>
          <input type="password" placeholder="password" />
        </div>
      </div>

      <!-- 下一步按钮 -->
      <div class="nextStep" @click="to_info()" >
        <p>Next</p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();


const to_info = () => {
  router.push("/passed");

};
</script>

<style lang="less" scoped>
@media (max-width: 575px) {
  .input_zone{
    margin-top: 40px;
  }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}



.sign_zone {
  display: flex;
  flex: 1;
}
.sign_pic {
  background-image: url("../assets/login_pic.jpg");
  height: 100vh;
  width: 40vw;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
  gap: 30px;
  position: relative;
  z-index: 1;
  h1 {
    font-size: 2rem;
    color: aliceblue;
  }
  h3 {
    width: 100%;
    color: aliceblue;
    text-align: right;
  }
}
.sign_pic::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.39); /* 半透明的黑色覆盖层 */
  z-index: -1; /* 确保覆盖层在背景图片上面 */
}

.login_form {
  width: 40vw;
  flex: 1;
  padding: 200px 30px 30vh 30px;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  // border: 1px solid gray;
  h1 {
    font-size: 3rem;
    max-width: 1000px;
    text-align: center;
    text-shadow: -1px -1px 0px #00b0b997, 1px 1px 0px #b9002b97;
  }
  h3 {
    text-align: center;
    background: linear-gradient(to right, #12c2e9,#c471ed,#f64f59); 
    background-size: 400% 400%;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    animation: gradient 4s ease infinite;
    text-shadow: 0px 0px 2px #c471ed7a;
  }
  .input_zone {
    width: 50%;
    .twoinone_Line {
      display: flex;
      justify-content: center;
      gap: 10px;
      .input_text {
        flex: 1;
        input {
          width: 100%;
        }
      }
    }

    .input_text {
      margin-bottom: 20px;
      flex: 1;
      input {
        width: 100%;
      }
    }
  }
}
.nextStep{
    background-color: #023047;
    color: white;
    width: 50%;
    text-align: center;
    height: 50px;
    line-height: 50px;
    border-radius: 10px;
}

input {
  height: 50px;
  border-radius: 10px;
  border: 1px solid rgba(83, 83, 83, 0.062);
  background-color: rgba(212, 212, 212, 0.062);
  box-sizing: border-box;
  padding: 20px;
  flex-shrink: 0;
}
</style>