<template>
  <my-nav></my-nav>
  <div class="show_zone">
    <h1>Please be patient</h1>
    <img src="../../assets/reviewing.png" alt="" srcset="" />
    <h3>We are carefully reviewing your registration application </h3>
    <h4>Check out our blog for updates!</h4>
    <h3></h3>
    <div class="btn signUp" @click="to_blog()">GO</div>
  </div>
</template>
<script setup>
import { onMounted, } from "vue";
import { useRouter } from "vue-router";
import { flowerDropPink } from "../../const/dropFollows";
const router = useRouter();

onMounted(()=>{
    // flowerDropPink();
    // setTimeout(to_info, 3000)
})
const to_blog = () => {
  router.push("/blog");

};
</script>
<style lang="less" scoped>
// 多端适配开始
/* 超大屏幕 */
@media (min-width: 1200px) {

}

/* 大屏幕 */
@media (min-width: 990px) and (max-width: 1199px) {
}

/* 中屏幕 */
@media (min-width: 768px) and (max-width: 991px) {
}

/* 小屏幕 */
@media (min-width: 576px) and (max-width: 767px) {
}

/* 超小屏幕 */
@media (max-width: 575px) {
.show_zone{
  padding: 50px 0 !important;
  margin: 0!important;
  width: 100vw!important;
}
}

// 多端适配结束
.show_zone {
  width: 60vw;
  height: 100vh;
  box-sizing: border-box;
  padding-top: 100px;
  margin: 0 auto;
  text-align: center;
  h1 {
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    text-shadow: -1px -1px 0px #00b0b997, 1px 1px 0px #b9002b97;
  }
  img {
    width: 20vw;
    min-width: 300px;
    margin-bottom: 50px;
    margin-top: 30px;
  }
  h3 {
    margin-top: 10px;
  }
  h4{
    color: gray;
    margin-top: 40px;
    font-size: 1rem;
  }
}
.btn {
  // background-color: antiquewhite;
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bolder;
  margin: 0 auto;
  margin-top: 30px;
}
.signUp {
  background-color: #0d68fa;
  border: 1px solid rgba(128, 128, 128, 0.336);
  color: #ffffff;
  transition: 0.09s ease-in-out;
}
.signUp:hover {
  background-color: #034fc9;
}
.signUp:active {
  background-color: #2578fd;
  box-shadow: 0 0 10px #0d68fa;
}
</style>